<template>
  <div class="mainwrap versionlist" v-loading="loading">
    <div class="title">
      版本管理
    </div>
    <div class="tablewrap">
      <el-table :data="tableData" style="width: 100%" stripe>
        <el-table-column prop="version" label="版本號"> </el-table-column>
        <el-table-column label="設備類型">
          <template slot-scope="scope">
            <span>{{scope.row.os==1?"安卓":"蘋果"}}</span>
          </template>
        </el-table-column>
        <el-table-column label="更新類型">
          <template slot-scope="scope">
            <span>{{scope.row.isForce?"强制更新":"非強制更新"}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="content" label="内容"></el-table-column>
        <el-table-column label="操作" width="220" v-if="permissionType === 2">
          <template slot-scope="scope">
            <el-button @click="handleEdit(scope.row)" type="primary" plain>
              编辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import * as api from "./api";
import { getStore} from '@/utils/storage';
export default {
  name: 'version',
    data(){
      return{
        loading:false,
        tableData:[],
        permissionType: -1
      }
    },
    created(){
      this.getVersionList();
      this.permissionType = parseInt(getStore("permissionType"));
    },
    methods:{
      getVersionList(){
        this.loading=true;
        api.getVersionList().then(res=>{
          if(res.systemCode===200){
            this.tableData=res.data;
          }
          this.loading=false;
        })
      },
      handleEdit(item){
        this.$router.push({
          path: "/cms/versionedit",
          query: {
            type: "edit",
            id: item.id,
          },
        });
      }
    }
}
</script>

<style lang="scss" scoped>
</style>
