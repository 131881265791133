import { getRequest,postRequest } from "@/http/request_service.js";
import { API } from "@/api/api.js"

export const getVersionList=(params)=>{
    return getRequest(API.versionList,params);
}
export const getVersionDetails=(params)=>{
    return getRequest(API.versionList+'/'+params);
}
export const versionEdit=(params)=>{
    return postRequest(API.versionEdit,params);
}