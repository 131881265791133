<template>
  <div class="mainwrap versionedit" v-loading="loading">
      <div class="title">版本管理編輯</div>
      <div class="formwrap">
            <el-form
                :model="versionForm"
                :rules="rules"
                ref="versionForm"
                label-width="150px"
                label-position="left"
            >
                <el-form-item label="版本" prop="version">
                    <el-input
                        v-model="versionForm.version"
                        placeholder="請輸入版本號"
                    ></el-input>
                </el-form-item>
                
                <el-form-item label="内容" prop="content" placeholder="請輸入内容">
                    <el-input type="textarea" v-model="versionForm.content"></el-input>
                </el-form-item>

                <el-form-item label="設備類型" prop="os">
                    <el-select v-model="versionForm.os" placeholder="請選擇設備類型">
                        <el-option
                            v-for="item in devicOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="更新類型" prop="isForce">
                    <el-select v-model="versionForm.isForce" placeholder="請選擇更新類型">
                        <el-option
                            v-for="item in updateOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
      </div>

      <div class="btnwrap">
        <div class="btnlist">
            <el-button type="primary" @click="onSubmit">提交</el-button>
            <el-button @click="cancel">取消</el-button>
        </div>
        </div>
  </div>
</template>

<script>
import * as api from "./api"
export default {
    data(){
        return{
            loading:false,
            devicOptions:[
                {label:"安卓",value:1},
                {label:"蘋果",value:2}
            ],
            updateOptions:[
                {label:"强制更新",value:true},
                {label:"非强制更新",value:false}
            ],
            versionForm:{
                id:"",
                version:"",
                content:"",
                os:"",
                isForce:""
            },
            rules:{
                version:[{ required: true, message: "請輸入版本號", trigger: "blur" }],
                content:[{ required: true, message: "請輸入内容", trigger: "blur" }],
                os:[{ required: true, message: "請選擇設備類型", trigger: "change" }],
                isForce:[{ required: true, message: "請選擇更新類型", trigger: "change" }],
            }
        }
    },
    created(){
        let queryObj = this.$route.query || {};
        if (queryObj.type && queryObj.type === "edit" && queryObj.id) {
            this.getVersionDetails(queryObj.id)
        }else{
            this.loading=false;
        }
    },
    methods:{
        getVersionDetails(id){
            this.loading=true;
            api.getVersionDetails(id).then(res=>{
                this.versionForm=res.data;
                this.loading=false;
            })
        },
        onSubmit(){
            this.$refs["versionForm"].validate(valid =>{
                if(valid){
                    this.loading=true;
                    api.versionEdit(this.versionForm).then(res=>{
                        if(res.systemCode===200){
                            this.$message.success('提交成功');
                            this.$router.push({ path: "/cms/version" });
                        }
                        this.loading=false;
                    })
                }
            })
        },
        cancel(){
            this.$router.push({ path: "/cms/version" });
        }
    }
}
</script>

<style lang="scss" scoped>
.versionedit {
  .formwrap {
    padding-top: 20px;
    padding-left: 20px;
    width: 500px;
    .inlineblock {
      .el-input {
        width: 80%;
        margin-right: 10px;
      }
    }
  }
}
</style>